import React from "react";
import Card from "@mui/material/Card";
import Carousel from "react-material-ui-carousel";

const ServiceCard = ({ title, images }) => {
	return (
		<div className="m-4">
			<h1 className="mt-4 underline underline-offset-4">{title}</h1>
			<Carousel duration={1500} animation={"slide"}>
				{images.length !== 0 &&
					images.map((image, index) => (
						<Card sx={{ minWidth: 400, minHeight: 400 }}>
							<img src={image} alt={`image-${title}-${index + 1}`} />
						</Card>
					))}
			</Carousel>
		</div>
	);
};

export default ServiceCard;
