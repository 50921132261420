// import HackingAndDemolitionImage from "../assets/Hacking and Demolition 1.jpg";
import ProtectionImage1 from "../assets/Protection 1.jpg";
import ProtectionImage2 from "../assets/Protection 2.jpg";
import PaintingImage1 from "../assets/Painting 1.jpg";
import PaintingImage2 from "../assets/Painting 2.jpg";
import ReinstatementImage1 from "../assets/Reinstatement 1.jpg";
import ReinstatementImage2 from "../assets/Reinstatement 2.jpg"; // Image not proper size
import ReinstatementImage3 from "../assets/Reinstatement 3.jpg";
import WashingAndCleaningImage1 from "../assets/Washing and Cleaning 1.jpg";
import WashingAndCleaningImage2 from "../assets/Washing and Cleaning 2.jpg"; // Image not proper size
import HaulageAndDisposalImage1 from "../assets/Haulage and Disposal 1.jpg";
import HaulageAndDisposalImage2 from "../assets/Haulage and Disposal 2.jpg";
import HaulageAndDisposalImage3 from "../assets/Haulage and Disposal 3.jpg"; // Image not proper size
import MovingImage1 from "../assets/Moving 1.jpg";
import MovingImage2 from "../assets/Moving 2.jpg"; // Image not proper size

export const services = [
	// { title: "Hacking & Demolition",  src: HackingAndDemolitionImage },
	{ title: "Protection Of Floor & Interior", images: [ProtectionImage1, ProtectionImage2] },
	{
		title: "Haulage & Disposal",
		images: [
			HaulageAndDisposalImage1,
			HaulageAndDisposalImage2,
			// HaulageAndDisposalImage3
		],
	},
	{
		title: "Reinstatement",
		images: [
			ReinstatementImage1,
			// ReinstatementImage2,
			ReinstatementImage3,
		],
	},
	{ title: "Painting", images: [PaintingImage1, PaintingImage2] },
	{
		title: "Washing & Cleaning",
		images: [
			WashingAndCleaningImage1,
			// WashingAndCleaningImage2
		],
	},
	{
		title: "Moving",
		images: [
			MovingImage1,
			// MovingImage2
		],
	},
];

export const contactFormServices = [
	{ title: "Hacking & Demolition" },
	{ title: "Protection Of Floor & Interior" },
	{ title: "Haulage & Disposal" },
	{ title: "Reinstatement" },
	{ title: "Painting" },
	{ title: "Washing & Cleaning" },
	{ title: "Moving" },
];
