import Navbar from "./components/Navbar";
import ServiceCard from "./components/ServiceCard";
import Contact from "./components/Contact";
import React from "react";

import { services } from "./constants/index";

import HackingAndDemolitionImage from "./assets/Hacking and Demolition 1 resized.jpg";

const App = () => {
	return (
		<div className="text-center bg-hacksome-purple">
			<Navbar />
			<div className="flex flex-col">
				<img src={HackingAndDemolitionImage} alt={"test"} />
			</div>

			<body className="flex flex-col">
				<div>
					<h1 className="font-bold underline underline-offset-4 text-3xl" id="services">
						About Us
					</h1>

					<div className="p-4 text-left justify-center flex flex-row w-full space-x-8 items-center">
						<iframe
							className="min-w-[250px] max-w-[650px] h-[350px] md:min-w-[450px] lg:min-w-[650px]"
							// width="650"
							// height="350"
							src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDh8Lxp2QhaL67mvqWT1VDaVtlAN5dhWiQ&q=111+North+Bridge+Rd,Singapore"
							allowFullScreen
						></iframe>
						<div className="text-justify space-y-6">
							<p className="max-w-md text-[10px] md:text-sm">
								Hacksome is a team of experienced professionals specializing in wrecking and
								demolition works. We provide top-quality workmanship and exceptional customer
								service to clients across a range of industries.
							</p>
							<p className="max-w-md text-[10px] md:text-sm">
								Our team is dedicated to delivering high-quality workmanship that you can rely on,
								whether you need a building demolished or a site cleared for redevelopment. Thank
								you for considering Hacksome for your wrecking and demolition needs.
							</p>
						</div>
					</div>
				</div>

				<div>
					<h1 className="font-bold underline underline-offset-4 text-3xl" id="services">
						Services
					</h1>
					<div className="flex flex-row flex-wrap">
						{services.length !== 0 &&
							services.map((service, index) => {
								return (
									<div className="basis-1/3">
										<ServiceCard title={service.title} images={service.images} />
									</div>
								);
							})}
					</div>
				</div>

				<div className="w-full">
					<h1 className="font-bold underline underline-offset-4 text-3xl" id="contact">
						Contact Us
					</h1>
					<Contact />
				</div>
			</body>
		</div>
	);
};

export default App;
