import React, { useState } from "react";
import { queryContactForm } from "../api";
import {
	Button,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Box,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { Modal } from "./Modal";
import { contactFormServices } from "../constants/index";

const Contact = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [serviceSelected, setServiceSelected] = useState([contactFormServices[0].title]);
	const [description, setDescription] = useState("");

	const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
	const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// You can add your logic here to send the form data to your server or API

		if (contactNumber.length <= 7) {
			setIsErrorMessageVisible(true);
		} else {
			const response = await queryContactForm(
				firstName,
				lastName,
				email,
				contactNumber,
				description,
				serviceSelected
			);

			if (response === "Success") {
				setIsSuccessMessageVisible(true);

				// Clear the form inputs
				setFirstName("");
				setLastName("");
				setEmail("");
				setContactNumber("");
				setDescription("");
			}
		}
	};

	const handlePhoneNumberChange = async (value) => {
		setContactNumber(value);
	};

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		// On autofill we get a stringified value.
		setServiceSelected(typeof value === "string" ? value.split(",") : value);
	};

	return (
		<div className="flex flex-row w-full justify-center">
			<form className="flex flex-col" onSubmit={handleSubmit}>
				<div className="flex flex-row">
					<div className="flex flex-row basis-1/2 m-4">
						<TextField
							required
							id="outlined-basic"
							label="First Name:"
							variant="outlined"
							onChange={(newValue) => setFirstName(newValue.target.value)}
						/>
					</div>
					<div className="flex flex-row basis-1/2 m-4">
						<TextField
							required
							id="outlined-basic"
							label="Last Name:"
							variant="outlined"
							onChange={(newValue) => setLastName(newValue.target.value)}
						/>
					</div>
				</div>

				<div className="m-4">
					<TextField
						required
						label="Email"
						variant="outlined"
						fullWidth
						onChange={(newValue) => setEmail(newValue.target.value)}
					/>
				</div>

				<div className="m-4 text-black flex">
					<p className="mr-4 mt-2">Phone Number:</p>
					<MuiPhoneNumber
						required
						defaultCountry={"sg"}
						onlyCountries={["sg", "my"]}
						onChange={handlePhoneNumberChange}
						className="w-8/12"
					/>
				</div>

				<Box className="m-4">
					<FormControl variant="outlined" className="w-full">
						<InputLabel id="test-select-label">Services Selected</InputLabel>
						<Select
							required
							style={{ width: "100%" }}
							variant="outlined"
							value={serviceSelected}
							onChange={handleChange}
							labelId="test-select-label"
							label={"Services Selected"}
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left",
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left",
								},
								getContentAnchorEl: null,
							}}
						>
							{contactFormServices.map((service) => (
								<MenuItem key={service.title} value={service.title}>
									{service.title}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				<div className="m-4">
					<TextField
						id="outlined-multiline-static"
						multiline
						required
						fullWidth
						minRows={4}
						variant="outlined"
						placeholder="Description for the service you require.
						E.g. Hacking of office walls or Moving of house"
						onChange={(newValue) => setDescription(newValue.target.value)}
					/>
				</div>

				<div className="m-4">
					<Button variant="contained" color="primary" type="submit">
						Submit
					</Button>
				</div>
			</form>

			<Modal
				setVisibility={setIsErrorMessageVisible}
				isModalVisible={isErrorMessageVisible}
				modalTitle={"Error Occurred"}
			>
				<div className="p-4">Phone Number is invalid.</div>
			</Modal>

			<Modal
				setVisibility={setIsSuccessMessageVisible}
				isModalVisible={isSuccessMessageVisible}
				modalTitle={"Your Inquiry Has Been Received Successfully!"}
			>
				<div className="p-4">
					Please give us 3-5 working days to get back to you on your inquiry!
				</div>
			</Modal>
		</div>
	);
};

export default Contact;
